<template>
  <div style="width: 100%">
    <div style="width: 100%" class="d-flex flex-wrap">
      <div>
        <div style="width: 300px; height: calc(100vh - 80px); position: fixed">
          <vxe-input
            v-model="keywords"
            type="search"
            placeholder="搜索周会"
            size="medium"
            style="width: 300px"
            clearable
            @change="getNoteList()"
          ></vxe-input>
          <div style="width: 300px; height: calc(100vh - 130px)">
            <vxe-table
              id="weekTable"
              row-id="id"
              ref="weekTable"
              :data="list"
              :border="false"
              resizable
              show-overflow
              show-header-overflow
              highlight-hover-row
              align="center"
              :loading="tableLoading"
              :column-config="{ resizable: true }"
              height="auto"
              size="small"
              :stripe="true"
              :sort-config="{ trigger: 'cell', multiple: true }"
              :empty-render="{ name: 'NotData' }"
              :tooltip-config="{
                showAll: false,
                enterable: true,
                theme: 'dark',
              }"
              @cell-click="cellClick"
              style="cursor: pointer"
              class="mt-2"
            >
              <vxe-column min-width="100" field="title" title="标题">
                <template #header="{ column }">
                  <div style="word-wrap: break-word; white-space: pre-wrap">
                    {{ column.title }}
                  </div>
                </template>
                <template #default="{ row }">
                  <div class="d-flex justify-start pl-1">
                    {{ row.title }}
                  </div>
                </template>
              </vxe-column>

              <vxe-column width="75" field="created_at" title="日期" sortable>
                <template #default="{ row }">
                  {{ row.created_at.split(" ")[0] }}
                </template>
              </vxe-column>

              <vxe-column width="50" field="user_name" title="作者">
                <template #default="{ row }">
                  {{ row.user_name }}
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </div>

        <div style="">
          <div style="width: calc(100vw - 300px); margin-left: 310px">
            <NoteContent style="width: 100%" :readItem="readItem"></NoteContent>
            <NoteComment
              style="width: calc(100vw - 400px)"
              :noteId="readItem.id"
              v-if="readItem.id"
            >
            </NoteComment>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Review from "@/api/review.js";
import Note from "@/api/note.js";
import moment from "moment";
import NoteContent from "./NoteContent";
import NoteComment from "@/components/Note/NoteComment";
export default {
  components: { NoteContent, NoteComment },
  props: {},
  data() {
    return {
      tableLoading: false,
      list: [],
      keywords: "每周复盘",
      readItem: {},
    };
  },
  mounted() {
    this.getNoteList();
  },
  methods: {
    getNoteList() {
      this.tableLoading = true;
      Note.getNoteList(this.keywords, [5], 1, 9999999)
        .then((res) => {
          this.list = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    cellClick({ row }) {
      this.readItem = row;
    },
  },
};
</script>
<style scoped="scoped"></style>
